import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CarouselControl = (props) => {
	const { id, direction, onClickHandler, directionText, className } = props;
	console.log('direction', direction);
	return (
		<div
		className={direction === 'prev' ? (
			'PhotoView-Slider__ArrowLeft'
		) : (
			'PhotoView-Slider__ArrowRight' 
		)}
			
			// className={classNames(className, `carousel-control-${direction}`)}
			//	type='button'
			data-bs-target={id || 'carousel'}
			data-bs-slide={direction}
			onClick={(e) => {
				e.preventDefault();
				onClickHandler();
			}}>
			{/* <span className={classNames(`carousel-control-${direction}-icon`)} aria-hidden='true' />
			<span className={classNames('visually-hidden')}>{directionText || direction}</span> */}
			<svg
				style={{
					fill: '#000',
					backgroundColor: '#f2f3f5',
					borderRadius: '50%',
				}}
				width='44'
				height='44'
				viewBox='0 0 768 768'>
				{direction === 'prev' ? (
					<path d='M640.5 352.5v63h-390l178.5 180-45 45-256.5-256.5 256.5-256.5 45 45-178.5 180h390z'></path>
				) : (
					<path d='M384 127.5l256.5 256.5-256.5 256.5-45-45 178.5-180h-390v-63h390l-178.5-180z'></path>
				)}
			</svg>
		</div>
	);
};
CarouselControl.propTypes = {
	id: PropTypes.string,
	direction: PropTypes.oneOf(['prev', 'next']).isRequired,
	onClickHandler: PropTypes.func.isRequired,
	directionText: PropTypes.string,
	className: PropTypes.string,
};
CarouselControl.defaultProps = {
	id: null,
	directionText: null,
	className: null,
};

export default CarouselControl;

// import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
// import './arrow.css';

// const CarouselControl = (props) => {
// 	const { id, direction, onClickHandler, directionText, className } = props;

// 	// Choose custom icon based on direction
// 	const arrowIcon = direction === 'prev'
// 		? '/left-arrow-icon.svg'
// 		: '/right-arrow-icon.svg';

// 	return (
// 		<button
// 			className={classNames(className, `carousel-control-${direction}`)}
// 			type='button'
// 			data-bs-target={id || 'carousel'}
// 			data-bs-slide={direction}
// 			onClick={(e) => {
// 				e.preventDefault();
// 				onClickHandler();
// 			}}>
// 			{/* Replace the Bootstrap icon with a custom SVG or image */}
// 			<img src={arrowIcon} alt={`${direction} arrow`} />
// 			<span className={classNames('visually-hidden')}>{directionText || direction}</span>
// 		</button>
// 	);
// };
// export default CarouselControl;
