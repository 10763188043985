import React from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import { useTranslation } from 'react-i18next';

const PageError = () => {
	const { t } = useTranslation();

	return (
		<div
			style={{
				backgroundImage: 'url(/image/oops.jpg)',
				backgroundSize: 'cover',
				width: '100%',
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: '#242632',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
			}}>
			<Button
				className='px-5 py-3'
				color='primary'
				isLight
				icon='Home'
				tag='a'
				href='/services'
				style={{
					position: 'absolute',
					bottom: '20%',
					fontSize: '1.2rem',
				}}>
				{t('Go Back to Homepage')}
			</Button>
		</div>
	);
};

export default PageError;
