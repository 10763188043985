import React, { useEffect, useContext, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Carousel from '../../../components/bootstrap/Carousel';
import CarouselSlide from '../../../components/bootstrap/CarouselSlide';
// import useMinimizeAside from '../../../hooks/useMinimizeAside';
import Pic from '../../../assets/img/wanna/richie/richie.png';
import Pic2 from '../../../assets/img/wanna/richie/richie2.png';
import Pic3 from '../../../assets/img/wanna/richie/richie3.png';
import Pic4 from '../../../assets/img/wanna/richie/richie4.png';
import Pic5 from '../../../assets/img/wanna/richie/richie5.png';
import Pic6 from '../../../assets/img/wanna/richie/richie6.png';
import Pic7 from '../../../assets/img/wanna/richie/richie7.png';
import Pic8 from '../../../assets/img/wanna/richie/richie8.png';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import CommonGridProductItem from '../../common/CommonGridProductItemSwl';
import Card, { CardActions } from '../../../components/bootstrap/Card';
import { userAuth } from '../../../contexts/userContext';
import useDarkMode from '../../../hooks/useDarkMode';
import apiServicewt from '../../../api/apiServicewt';
import { motion } from 'framer-motion';
import { useMediaQuery } from '@mui/material';
import { layoutMenu } from '../../../menu';
import '../../presentation/auth/Home.css';
import { useTranslation } from 'react-i18next';
const Home = () => {
	// useMinimizeAside();
	const { t } = useTranslation();

	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	const [ref, { height }] = useMeasure();
	/*const matches = useMediaQuery('(max-width:600px)');
	const matches750 = useMediaQuery('(max-width:750px)');
	const matches850 = useMediaQuery('(max-width:850px)');
	const matches950 = useMediaQuery('(max-width:950px)');
	const matches1000 = useMediaQuery('(max-width:1000px)');
	const matches1150 = useMediaQuery('(max-width:1150px)');
	const matches1250 = useMediaQuery('(max-width:1250px)');*/
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'dark'];
	const [selectedImage, setSelectedImage] = useState(null);

	const [data, setData] = useState([]);
	const [vslider, setVslider] = useState(null);
	const [editItem, setEditItem] = useState(null);
	const [editPanel, setEditPanel] = useState(false);

	const { setIsOpen } = useTour();
	useEffect(() => {
		const timeout = setTimeout(() => setIsOpen(true), 3000);
		return () => {
			clearTimeout(timeout);
		};
	}, []);
	useEffect(() => {
		const fetchata = async () => {
			const item = { action: 'services_list_wl' };
			const flechdata = await apiServicewt(item, navigate);

			if (flechdata.data) {
				setData(flechdata.data);

				// if(Array.isArray(JSON.parse(flechdata.data.filepath))){
				// 	const imageArray= JSON.parse(flechdata.data.filepath)
				// 	// const slideimg = imageArray.map((d,i)=>({
				// 	// 	url: d,
				// 	// 	caption: 'Slide '+ i+1,
				// 	// }))
				// 	// setSlideImages(slideimg)
				// 	console.log("single img",imageArray)
				// }
			}
		};
		fetchata();
	}, []);

	//slider

	useEffect(() => {
		const fetchata = async () => {
			const item = { action: 'slider_list' };
			const flechdata = await apiServicewt(item, navigate);
			if (Array.isArray(flechdata.data)) {
				//console.log('slider active', flechdata.data);
				const filterslide = flechdata.data.filter((e) => e.status === '1');
				if (filterslide.length > 0) {
					setVslider(filterslide);
				}
			}
		};
		fetchata();
	}, []);

	function handleRemove(id) {
		const newData = data.filter((item) => item.id !== id);
		setData(newData);
	}

	function handleEdit(id) {
		const newData = data.filter((item) => item.id === id);
		setEditItem(newData[0]);
	}

	const formik = useFormik({
		initialValues: {
			name: '',
			price: '',
			stock: '',
			category: '',
		},
		onSubmit: (values) => {
			setEditPanel(false);
		},
	});

	useEffect(() => {
		if (editItem) {
			formik.setValues({
				name: editItem.name,
				price: editItem.price,
				stock: editItem.stock,
				category: editItem.category,
			});
		}
		return () => {
			formik.setValues({
				name: '',
				price: '',
				stock: '',
				category: '',
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editItem]);

	const images = [
		{ id: 'Pic', img: Pic },
		{ id: 'Pic2', img: Pic2 },
		{ id: 'Pic3', img: Pic3 },
		{ id: 'Pic4', img: Pic4 },
		{ id: 'Pic5', img: Pic5 },
		{ id: 'Pic6', img: Pic6 },
		{ id: 'Pic7', img: Pic7 },
		{ id: 'Pic8', img: Pic8 },
	];

	const _gallery = (
		<div className='row g-4'>
			{images.map((item, index) => (
				<div key={item.id} className='col-xxl-2 col-lg-3 col-md-6'>
					<button
						type='button'
						onClick={() => setSelectedImage(item.img)}
						className={classNames(
							'ratio ratio-1x1',
							'rounded-2',
							'border-0',
							`bg-l${darkModeStatus ? 'o25' : '25'}-${colors[index % 7]}`,
							`bg-l${darkModeStatus ? 'o50' : '10'}-${colors[index % 7]}-hover`,
						)}>
						<img
							src={item.img}
							alt={item.id}
							width='100%'
							height='auto'
							className='object-fit-contain p-4'
						/>
					</button>
				</div>
			))}
		</div>
	);

	const SecondLayout = ({ d, i }) => {
		return (
			<CarouselSlide key={i}>
				<a href={d.url} target='_blank'>
					<div
						key={i}
						className='carousel-slide-bg'
						style={{
							//width: '1800px',
							// width: '100%',
							display: 'flex',
							// objectFit:'cover',
							//cursor: 'pointer',
							//backgroundPosition: 'left',
							//backgroundImage: `url(${d.slider_img})`,
						}}>
						<img src={d.slider_img} />
					</div>
				</a>
			</CarouselSlide>
		);
	};

	return (
		<PageWrapper>
			<Page container='fluid'>
				{/* <div className='display-4 fw-bold py-3'>Services</div> */}
				<SubHeader>
					<SubHeaderLeft>
						<span>schedulerdesk.com</span>
						<SubheaderSeparator />
						<span className='text-muted'>
							{data.length} {t('Services')}
						</span>
					</SubHeaderLeft>
					<SubHeaderRight>
						<Button
							// color={darkModeStatus ? 'light' : 'dark'}
							style={{ borderRadius: '10px' }}
							color='success'
							isLight
							icon='Login'
							onClick={() => {
								// setEditItem(null);
								// setEditPanel(true);
								navigate('/login?type=login');
							}}>
							{t('Log In')}
						</Button>
						<Button
							style={{ borderRadius: '10px' }}
							color='info'
							isLight
							icon='PersonAddAlt'
							onClick={() => {
								navigate('/login?type=reg');
							}}>
							{t('Sign Up')}
						</Button>
					</SubHeaderRight>
				</SubHeader>

				<br />
				<br />
				{/* slider */}

				{vslider && (
					<div
						className='col-12'
						style={{
							aspectRatio: '16/5',
							maxHeight: '100%',
							maxWidth: '99%',
							alignSelf: 'center',
							borderRadius: '10px',
							overflow: 'hidden',
						}}>
						<Carousel
							isHoverPause
							isRide
							rounded={0}
							//height={height || matches? 225 : matches750 ? 275 :  matches850 ? 300 : matches950? 325 :matches1000? 350 :matches1150? 375 :matches1250? 400 : 450}
							height={height || '100%'}
							isDark={darkModeStatus}>
							{/* {data?.map((item) => ( */}
							{/* <CarouselSlide>
								<div className='row align-items-center h-100'>
									<div
										className='col-6 carousel-slide-bg'
										style={{ backgroundImage: `url(${WannaImg1})` }}
									/>
									<div className='col-6'>
										<h2>BBBB</h2>
										<p className='lead'>AAA</p>
										<Button
											color={darkModeStatus ? 'light' : 'dark'}
											onClick={() =>
												navigate(
													`../${demoPages.sales.subMenu.productsGrid.path}`,
												)
											}>
											Click
										</Button>
									</div>
								</div>
							</CarouselSlide> */}
							{/* ))}
							 */}

							{/* <CarouselSlide background={WannaImg5} />

							<CarouselSlide>
								<div className='row align-items-center h-100'>
									<div className='col-6 text-end'>
										<h2>Customize</h2>
										<h5>You can design your own screens</h5>
										<Button
											color={darkModeStatus ? 'light' : 'dark'}
											onClick={() =>
												navigate(
													`../${demoPages.sales.subMenu.dashboard.path}`,
												)
											}>
											Click
										</Button>
									</div>
									<div
										className='col-6 carousel-slide-bg'
										style={{ backgroundImage: `url(${WannaImg2})` }}
									/>
								</div>
							</CarouselSlide> */}
							{vslider?.map((d, i) => {
								/*if (d.display_type === 'type1') {
									return <DefultLayout d={d} i={i} />;
								} else if (d.display_type === 'type2') {
									return <SecondLayout d={d} i={i} />;
								} else {
									return <DefultLayout d={d} i={i} />;
								}*/
								return <SecondLayout d={d} i={i} key={i} />;
							})}
						</Carousel>
					</div>
				)}

				{/* slider end*/}

				<br />
				{/* <br /> */}
				<div
					className='inner-wrapper'
					// className='row'
					/*style={{
						width: '100%',
						display: 'flex',
						//  flexDirection: 'column',
						gap: '35px',
						flexWrap: 'wrap',
						justifyContent: 'center',
						alignItems: 'center',
					}}*/
				>
					{data?.map((item) => (
						<div
							className='sub_inner_wrapper'
							/*style={{
								//backgroundColor:"red",
								marginBottom: '15px',
							}}*/
							key={item.id}
							// className='col-xxl-3 col-xl-4 col-md-6'
							onClick={() => {
								navigate(
									`/${layoutMenu.cbookingPages.subMenu.view_servwl.path}/${item.id}`,
								);
							}}>
							<CommonGridProductItem
								id={item.id}
								name={item.name}
								category={item.catname}
								// img={"https://apis.schedulerdesk.com/service/"+item.filepath}
								// img={item.filepath}
								img={JSON.parse(item.filepath)[0]}
								color='#46bcaa'
								price={item.price}
								editAction={() => {
									setEditPanel(true);
									handleEdit(item.id);
								}}
								deleteAction={() => handleRemove(item.id)}
							/>
						</div>
					))}
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Home;
