// import ReactCountryFlag from 'react-country-flag';
// import useDarkMode from '../../../../hooks/useDarkMode';
// import Select from 'react-select';

// const CountryFlagWithPhoneCodeSelector = ({ onSelect, tableOption }) => {

// 	const { darkModeStatus } = useDarkMode();

// 	const handleCountrySelect = (country) => {
// 		onSelect(country);
// 	};

// 	const customOptionLabel = (country) => (
// 		<div className='d-flex flex-row'>
// 			<ReactCountryFlag
// 				countryCode={country.code}
// 				svg
// 				style={{
// 					width: '2em',
// 					height: '2em',
// 				}}
// 			/>
// 			<span style={{ marginLeft: '5px' }}>{country.phoneCode}</span>
// 		</div>
// 	);

// 	return (
// 		<div>
// 			<Select
// 				components={{ IndicatorSeparator: null }}
// 				placeholder={'Phone Code'}
// 				onChange={(e) => handleCountrySelect(e)}
// 				formatOptionLabel={customOptionLabel}
// 				getOptionValue={(option) => option.phoneCode}
// 				styles={{
// 					singleValue: (provided) => ({
// 						...provided,
// 						 color: darkModeStatus ? 'white' : '#323232',
// 					}),
// 					control: (base, state) => ({
// 						...base,
// 						background: darkModeStatus ? '#212529' : '#f8f9fa',
// 						borderRadius: '1rem',
// 						 border: (state.isFocused ? darkModeStatus ? '3px solid #36345a' : '3px solid #dad6f4' : '3px solid transparent'),
// 						//  border: isError ? '2px solid red' :(state.isFocused ? darkModeStatus ? '3px solid #36345a' : '3px solid #dad6f4' : '3px solid transparent'),
// 						boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
// 						'&:hover': {
// 							 borderColor: (state.isFocused ? darkModeStatus ? '#36345a' : '#dad6f4' : 'transparent'),
// 							//  borderColor: isError ? 'red':(state.isFocused ? darkModeStatus ? '#36345a' : '#dad6f4' : 'transparent'),
// 						},
// 					}),

// 					dropdownIndicator: (styles,state) => ({
// 						...styles,
// 						// paddingBottom: 2,
// 						// paddingTop : 2,
// 						color :   '#323232',
// 						'&:hover': {
// 							color:  '#323232',
// 						},
// 						color :  darkModeStatus ? '#323232' : '#6c757d',
// 						'&:hover': {
// 							color: darkModeStatus ? '#323232' : '#6c757d',
// 						},
// 					}),

// 					menu: (base) => ({
// 						...base,
// 						borderRadius: '1rem',
// 						border: '1px solid #5c5d5f',
// 						background: darkModeStatus && '#212529',
// 					}),
// 					option: (provided, state) => ({
// 						...provided,
// 						backgroundColor: 'transparent',
// 						color: (state.isSelected && !darkModeStatus) &&'black',
// 						'&:hover': {
// 							backgroundColor: 'rgb(38, 132, 255)',
// 						},
// 					}),
// 					placeholder: (base) => ({
// 						...base,
// 						color: '#323232',
// 						 color: darkModeStatus ? 'white' : '#323232',
// 						fontWeight: '600',
// 					}),
// 					input: (base) => ({
// 						...base,
// 						color: darkModeStatus ? 'white' : '#323232',
// 						fontWeight: '600',
// 					}),
// 				}}
// 				options={tableOption}
// 			/>
// 		</div>
// 	);
// };

// export default CountryFlagWithPhoneCodeSelector;

import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import Select from 'react-select';
import useDarkMode from '../../../../hooks/useDarkMode';
import { useTranslation } from 'react-i18next';

const CountryFlagWithPhoneCodeSelector = ({ onSelect, tableOption }) => {
	const { t } = useTranslation();
	const { darkModeStatus } = useDarkMode();

	const handleCountrySelect = (country) => {
		onSelect(country);
	};

	const customOptionLabel = (country) => (
		<div className='d-flex flex-row align-items-center'>
			<ReactCountryFlag
				countryCode={country.code}
				svg
				style={{
					width: '2em',
					height: '2em',
				}}
			/>
			<span style={{ marginLeft: '5px' }}>{country.phoneCode}</span>
		</div>
	);

	return (
		<div>
			<Select
				components={{ IndicatorSeparator: null }}
				placeholder={t('Phone Code')}
				onChange={(e) => handleCountrySelect(e)}
				formatOptionLabel={customOptionLabel}
				getOptionValue={(option) => option.phoneCode}
				styles={{
					singleValue: (provided) => ({
						...provided,
						color: darkModeStatus ? 'white' : '#323232',
					}),
					control: (base, state) => ({
						...base,
						background: darkModeStatus ? '#212529' : '#f8f9fa',
						borderRadius: '1rem',
						minHeight: '47.5px',
						border: state.isFocused
							? darkModeStatus
								? '3px solid #36345a'
								: '3px solid #dad6f4'
							: '1px solid #343a40',
						//  border: isError ? '2px solid red' :(state.isFocused ? darkModeStatus ? '3px solid #36345a' : '3px solid #dad6f4' : '3px solid transparent'),
						boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
						'&:hover': {
							borderColor: state.isFocused
								? darkModeStatus
									? '#36345a'
									: '#dad6f4'
								: '#343a40',
							//  borderColor: isError ? 'red':(state.isFocused ? darkModeStatus ? '#36345a' : '#dad6f4' : 'transparent'),
						},
					}),

					dropdownIndicator: (styles, state) => ({
						...styles,
						// paddingBottom: 2,
						// paddingTop : 2,
						color: '#323232',
						'&:hover': {
							color: '#323232',
						},
						color: darkModeStatus ? '#323232' : '#6c757d',
						'&:hover': {
							color: darkModeStatus ? '#323232' : '#6c757d',
						},
					}),

					menu: (base) => ({
						...base,
						borderRadius: '1rem',
						border: '1px solid #5c5d5f',
						background: darkModeStatus && '#212529',
					}),
					option: (provided, state) => ({
						...provided,
						backgroundColor: 'transparent',
						color: state.isSelected && !darkModeStatus && 'black',
						'&:hover': {
							backgroundColor: 'rgb(38, 132, 255)',
						},
					}),
					placeholder: (base) => ({
						...base,
						color: '#323232',
						color: darkModeStatus ? 'white' : '#323232',
						fontWeight: '600',
					}),
					input: (base) => ({
						...base,
						color: darkModeStatus ? 'white' : '#323232',
						fontWeight: '600',
					}),
				}}
				options={tableOption}
			/>
		</div>
	);
};

export default CountryFlagWithPhoneCodeSelector;
